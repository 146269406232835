export type TTODO = any; // TODO: Remove.

export type TNullableString = string | null;

export type TOrderType = "takeout" | "delivery";
export type TOrderingFor = "now" | "later";

export enum TRestaurantType {
  NouriaKitchen = "nouria-kitchen",
  Amatos = "amatos",
  KrispyKrunch = "krispy-krunch",
  NouriaCafe = "nouria-cafe",
  Normal = "normal"
}

export type TStoreRestaurant = {
  id: string;
  url?: string;
  name?: string;
  types?: TRestaurantType[];
}

export type TStoreOrder = {
  id: string;
  items?: number;
  restaurant: TStoreRestaurant,
  basics?: boolean;
  timestamp: number;
};

export type TStoreOrders = TStoreOrder[];

export type TStoreRestaurantCacheEntry = TStoreRestaurant & {
  cacheKey: string;
  cacheTimestamp: number;
};

// export type TCartItemOptions = {
//   key: string;
//   value: { name: string; quantity: number }[];
// }[];

export type TCartItemOptions = {
  [key: string]: { name: string; quantity: number }[];
};

export type TMenuItemConfiguration = {
  size: string;
  quantity: number;
  options: TCartItemOptions;
  notes?: string;
};

export type TComboMenuItemConfiguration = {
  id: string;
  options: TCartItemOptions;
  notes?: string;
};

export interface IOrder {
  type: TOrderType;
  orderingFor: TOrderingFor;
  orderDate?: string;
  eta?: string;
  primaryAddress?: string;
  secondaryAddress?: string;
  zip?: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export type TAuthGrant = {
  result: "success" | "error";
  error?: string;
  authorizationGrant: string;
  expiresIn: number;
  scope: string[];
};

export type TMobileCardPayTokens = {
  provider: string;
  payment_data: string;
  is_test: boolean;
  billing_name: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_country?: string;
  billing_zip?: string;
};

export type TAuthCookie = {
  username: string;
  valid: boolean;
  access_token: string;
  refresh_token: string;
  expires_at: number;
  expires_in?: number;
  printedCardNumber: string;
};

export type TAccountInfo = {
  id: string;
  email: string;
  addresses: {
    px_id: number;
    address: string;
    address_line_2: string;
    zip: string;
    id: string;
    location: null;
    company?: string;
  }[];
  cards: any[];
  name: string;
  first_name: string;
  last_name: string;
  phones: string[];
  third_party_identifiers: string;
  api_request_id: string;
};

export type TAccountCreate = {
  name?: string;
  first_name: string;
  last_name: string;
  zip?: string;
  mobile_phone?: string;
  email: string;
  password: string;
  confirm_password: string;
};

export type TAccountUpdate = {
  name?: string;
  access_token: string;
  first_name?: string;
  last_name?: string;
  email?: string;
};

export type TAccountUpdatePTX = {
  username: string;
  access_token: string;
  account_id?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  mobile_phone?: string;
  password?: string;
  confirm_password?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state_province?: string;
};

export type TContactUpdate = {
  access_token: string;
  addresses: {
    id: string;
    address: string;
    address_line_2: string;
    zip: string;
    company?: string;
  }[];
  phones: string[];
};

export type TAccountPassword = {
  access_token: string;
  old_password: string;
  new_password: string;
  confirm_password: string;
};