import { paytronixAuthenticate, paytronixRegister } from "api/endpoints";
import { CheckboxField } from "components/form/CheckboxField";
import FormErrorDisplay from "components/form/Error";
import InputField from "components/form/InputField";
import ArrorRightIcon from "components/icons/ArrowRight";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { BrazeEvent, brazeEvent } from "integrations/braze";
import { useCallback, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, redirect } from "react-router-dom";
import { mapErrorToRegistrationField } from "utils/paytronixErrors";
import { formatPhoneNumber } from "utils/string";

type RegisterInputs = {
  first_name: string;
  last_name: string;
  email: string;
  zip: string;
  mobile_phone: string;
  password: string;
  confirm_password: string;
  terms: boolean;
  sms_consent: boolean;
  email_consent: boolean;
};

const RegisterForm = () => {
  const { fieldErrors, clearErrors, checkForPaytronixError, handleApiError } =
    useAuthContext();
  const appContext = useAppContext();
  const authContext = useAuthContext();
  const methods = useForm<RegisterInputs>({
    mode: "onChange",
  });
  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const requirePhoneNumber = watch("sms_consent");

  const registerProfile = useCallback(
    async (change: {
      first_name: string;
      last_name: string;
      email: string;
      zip: string;
      mobile_phone: string;
      password: string;
      confirm_password: string;
      terms: boolean;
      sms_consent: boolean;
      email_consent: boolean;
    }) => {
      try {
        clearErrors("actions.auth.register");
        const profile = await paytronixRegister({
          ...change,
        }).fetch();
        const registerSuccessful = checkForPaytronixError(
          profile,
          "actions.auth.register"
        );
        // Attempt authentication
        if (!registerSuccessful) return false;
        const authenticate = await paytronixAuthenticate(
          change.email,
          change.password
        ).fetch();
        if (authenticate.error !== undefined) {
          handleApiError(authenticate.error, "actions.auth.register");
          return false;
        }
        appContext.setAuthCookie(authenticate);
        // Send Braze event
        brazeEvent(BrazeEvent.Online_Order_New_Account, {
          email: change.email,
          first_name: change.first_name,
          last_name: change.last_name,
          phone: change.mobile_phone,
          zip: change.zip,
          email_subscribe: change.email_consent ? "subscribed" : "unsubscribed",
          subscription_group_id: change.sms_consent
            ? "989e4f4e-70b8-4a0f-a1c6-fc2b1000839e"
            : "",
          subscription_state: change.sms_consent ? "subscribed" : "unsubscribed",
        });
        return true;
      } catch (error: any) {
        appContext.setNetworkError(error.message, error);
        if (error.response.data)
          checkForPaytronixError(
            error.response.data,
            "actions.auth.update_profile"
          );
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContext]
  );

  const onSubmit: SubmitHandler<RegisterInputs> = async ({
    first_name,
    last_name,
    email,
    zip,
    mobile_phone,
    password,
    confirm_password,
    terms,
    sms_consent,
    email_consent,
  }) => {
    // Add your login logic here
    const results = await registerProfile({
      first_name,
      last_name,
      email,
      zip,
      mobile_phone,
      password,
      confirm_password,
      terms,
      sms_consent,
      email_consent,
    });

    if (results) {
      if (authContext.redirect) {
        window.location.href = authContext.redirect;
        return true;
      }
      return redirect("/profile");
    }
  };
  useEffect(() => {
    Object.keys(fieldErrors).forEach((key) => {
      const field = mapErrorToRegistrationField(key);
      methods.setError(field, {
        type: "manual",
        message: fieldErrors[key],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);

  return (
    <>
      <div
        className="block-background d-none d-lg-block"
        style={{ width: "39%" }}
      >
        <img
          className="block-background-image"
          src="/wp-content/themes/nouria/assets/images/content/section-bg-5.jpeg"
          alt="#"
        />
      </div>
      <div className="row justify-content-end gy-2">
        <div className="col-lg-7">
          <h4 className="mb-1">Register</h4>
          <FormErrorDisplay error="actions.auth.register" />
          <hr className="my-3" />
          <FormProvider {...methods}>
            <form className="row g-2" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6">
                <InputField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name..."
                  validation={{
                    required: "First Name is required",
                    maxLength: {
                      value: 30,
                      message: "First Name must be less than 30 characters",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name..."
                  validation={{
                    required: "Last Name is required",
                    maxLength: {
                      value: 30,
                      message: "Last Name must be less than 30 characters",
                    },
                  }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="Email..."
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "That doesn't look like an email",
                    },
                    maxLength: {
                      value: 100,
                      message: "Email must be less than 100 characters",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  name="zip"
                  label="Zip Code"
                  placeholder="Zip Code..."
                  validation={{
                    pattern: {
                      value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                      message: "That doesn't look like a US zip code",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  name="mobile_phone"
                  type="tel"
                  label="Mobile Phone"
                  placeholder="Mobile Phone..."
                  formatter={formatPhoneNumber}
                  validation={{
                    required: requirePhoneNumber
                      ? "Mobile phone is required"
                      : undefined,
                    pattern: {
                      value: /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im,
                      message: "That doesn't look like a phone number",
                    },
                  }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password..."
                  helpText="Password must be at least 6 characters"
                  validation={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="confirm_password"
                  label="Repeat Password"
                  type="password"
                  placeholder="Repeat Password..."
                  validation={{
                    required: "required",
                    validate: (value: string) =>
                      value === methods.getValues("password") ||
                      "The passwords do not match",
                  }}
                />
              </div>

              <div className="col-12">
                <CheckboxField
                  name="email_consent"
                  label="YES! Send me promotional emails about the many ways I can save through the Nouria Rewards program."
                />
              </div>

              <div className="col-12">
                <CheckboxField
                  name="sms_consent"
                  label="YES! Send me periodic SMS Messages about the many ways I can earn through the Nouria Rewards program. Message and Data Rates may apply. Message frequency may vary. Text STOP to unsubscribe at any time."
                />
              </div>
              <div className="col-12">
                <CheckboxField
                  name="terms"
                  label="I understand that by clicking join, I agree to the Terms & Conditions and Privacy Policy of the Nouria Rewards program."
                  validation={{
                    required: "You must agree to the terms and conditions",
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <div className="hstack gap-2">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm ms-auto"
                    disabled={isSubmitting || !isValid ? true : false}
                  >
                    {isSubmitting ? (
                      <>Registering ...</>
                    ) : (
                      <>
                        Create Account <ArrorRightIcon />
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr className="mb-1" />
              </div>
              <div className="col-12">
                <div className="hstack gap-2">
                  <Link to="/forgot" className="fs-sm link-text-muted">
                    Forgot Password?
                  </Link>
                  <Link to="/login" className="fs-sm link-text-muted ms-auto">
                    Sign In
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
