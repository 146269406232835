import React, { useEffect } from "react";
import _ from "lodash";
import { useRestaurantContext } from "contexts/restaurant-context";
import { TRestaurantType } from "types";

type TFilter = TRestaurantType;

interface IMegaMenuProps {}

const MegaMenu: React.FC<IMegaMenuProps> = () => {
  const { dataDrought, restaurantDetailsLoaded } = useRestaurantContext();

  useEffect(() => {
    const currentMegaMenu = document.getElementById("nouria-megamenu");
    const defaultMegaMenu = document.getElementById("nouria-default-megamenu");

    if (currentMegaMenu) {
      currentMegaMenu.setAttribute(
        "style",
        dataDrought || !restaurantDetailsLoaded ? "display: none;" : ""
      );
    }

    if (defaultMegaMenu) {
      defaultMegaMenu.setAttribute(
        "style",
        dataDrought || !restaurantDetailsLoaded ? "" : "display: none;"
      );
    }
  }, [dataDrought, restaurantDetailsLoaded]);

  if (dataDrought) {
    return <></>;
  }

  return <MegaMenuInterface isClosestLocation={false} />;
};

export default MegaMenu;

interface IMegaMenuInterfaceProps {
  isClosestLocation?: boolean;
}

const MegaMenuInterface: React.FC<IMegaMenuInterfaceProps> = ({
  isClosestLocation,
}) => {
  const { menu, restaurantTypes, restaurantBaseUrl } = useRestaurantContext();

  if (!menu) {
    return (
      <div className="row gx-2">
        <div className="col-12">
          <div className="p-1 p-lg-3">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row gx-2">
      <div className="col-7">
        <div className="p-1 p-lg-3">
          <Logo
            filter={
              restaurantTypes && restaurantTypes.length > 0
                ? restaurantTypes![0]
                : TRestaurantType.Normal
            }
          />
          <div className="row row-cols-sm-2 row-cols-lg-3 g-2">
            {menu.menu.slice(0, 6).map((menuItem: any, menuItemIdx: number) => (
              <Item
                key={menuItemIdx}
                menuItem={menuItem}
                restaurantUrl={restaurantBaseUrl}
              />
            ))}
          </div>
          {!!isClosestLocation && (
            <div className="small py-2">
              <p>Showing menu for the location closest to you.</p>
            </div>
          )}
        </div>
      </div>
      <div className="col-5">
        <div className="h-100 p-3 bg-body-secondary rounded-3">
          <div className="row g-2">
            <div className="col-lg-6">
              <h6 className="megamenu-title">
                <a href="/our-menu/cafe-nouria/">Café Nouria</a>
              </h6>
              <ul className="megamenu-list">
                <li>
                  <span>Iced Coffee</span>
                </li>
                <li>
                  <span>Muffins</span>
                </li>
                <li>
                  <span>Coffee</span>
                </li>
                <li>
                  <span>Frazil</span>
                </li>
              </ul>
              <a
                className="btn btn-link btn-sm mt-1 fs-sm"
                href="/our-menu/cafe-nouria/ "
              >
                More Info {/*<?php sprite('arrow-right', 'ms-1'); ?>*/}
              </a>
            </div>
            <div className="col-lg-6">
              <h6 className="megamenu-title">
                <a href="/our-menu/my-nouria/ ">My Nouria</a>
              </h6>
              <ul className="megamenu-list">
                <li>
                  <span>Drinks</span>
                </li>
                <li>
                  <span>Pastries</span>
                </li>
                <li>
                  <span>Snacks</span>
                </li>
                <li>
                  <span>Candy</span>
                </li>
              </ul>
              <a
                className="btn btn-link btn-sm mt-1 fs-sm"
                href="/our-menu/my-nouria/"
              >
                More Info {/*<?php sprite('arrow-right', 'ms-1'); ?>*/}
              </a>
            </div>
          </div>
          <hr className="my-3" />
          <p className="mb-1 fs-sm">
            {/*<?php sprite('location-pin', 'me-1'); ?>*/} Other Nouria
            offerings:
          </p>
          <ul className="megamenu-list megamenu-list-split">
            <li>
              <a href="/our-menu/premium-cigars/">Premium Cigars</a>
            </li>
            <li>
              <a href="/our-menu/beer-wine/">Beer and Wine</a>
            </li>
            <li>
              <a href="/our-menu/liquor/">Liquor</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

interface IItemInterfaceProps {
  menuItem: any;
  restaurantUrl?: string;
}

const Item: React.FC<IItemInterfaceProps> = ({ menuItem, restaurantUrl }) => {
  const { menu } = useRestaurantContext();

  if (!menu) {
    return <></>;
  }

  return (
    <div>
      <a
        href={`${restaurantUrl}#${_.kebabCase(menuItem.name)}`}
        className="menu-card"
      >
        <figure className="menu-card-image">
          <img
            src={
              menuItem.image ||
              "/wp-content/themes/nouria/assets/images/content/no-image-available.png"
            }
            alt={menuItem.name}
          />
        </figure>
        <h6 className="menu-card-title fs-sm">{menuItem.name}</h6>
      </a>
    </div>
  );
};

interface ILogoProps {
  filter: TFilter;
}

const Logo: React.FC<ILogoProps> = ({ filter }) => {
  if (filter === TRestaurantType.NouriaKitchen) {
    return (
      <span className="megamenu-logo">
        <img
          src="/wp-content/themes/nouria/assets/images/content/nouria-kitchen-logo-alt.png"
          alt="#test"
        />
      </span>
    );
  }

  if (filter === TRestaurantType.Amatos) {
    return (
      <h6 className="megamenu-title">Noruria's Kitchen featuring Amato's</h6>
    );
  }

  if (filter === TRestaurantType.KrispyKrunch) {
    return <h6 className="megamenu-title">Krispy Kruncky</h6>;
  }

  if (filter === TRestaurantType.NouriaCafe) {
    return <h6 className="megamenu-title">Café Nouria</h6>;
  }

  return <></>;
};
