import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import config from "config";
// import { phpToDate, phpToUndefined } from "utils/types";
// import * as z from "zod";

// eslint-disable-next-line
export interface HttpRequestConfig<T = any> extends AxiosRequestConfig {
  // validator?: z.ZodSchema<unknown>;
}
export interface HttpResponse<T = any> extends AxiosResponse<T> {}
export class HttpError<T = any> extends AxiosError<T> {}


const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export default class HttpClient<T> {
  private static _instance: AxiosInstance;
  config?: HttpRequestConfig<T>;
  fetch: () => Promise<T>;
  // Allow child classes to disable paytronix keys
  service: "wp" | "od" | "ptx" = "od";
  auth_grant: string | null = null;
  guestIdentifier: { name: string; value: string } | null = null;
  needsMerchantId: boolean = false;
  perimeterXDebug: boolean = false;

  /**
   * Http layer suitable for useSWR
   * @param request axios compatible configuration object
   */
  constructor() {
    if (!HttpClient._instance) {
      HttpClient._instance = axios.create({
        baseURL: `${config.PAYTRONIX_OD_API_URL}/api/v1`,
        headers,
        responseType: "json",
      });
    }

    this.fetch = () => {
      throw new Error("Cannot fetch an unconfigured request.");
    };
  }

  setAuthGrant(grant: string | null): HttpClient<T> {
    this.auth_grant = grant;
    return this;
  }
  setGuestIdentifier(name: string, value: string): HttpClient<T> {
    this.guestIdentifier = { name, value };
    return this;
  }
  setNeedsMerchantId(): HttpClient<T> {
    this.needsMerchantId = true;
    return this;
  }

  setWordpress(): HttpClient<T> {
    this.service = "wp";
    return this;
  }
  setPaytronixManagement(): HttpClient<T> {
    this.service = "ptx";
    return this;
  }

  setPerimeterXDebug(debug: boolean): HttpClient<T> {
    console.log("Setting perimeterXDebug:", this, debug);
    this.perimeterXDebug = debug;
    return this;
  }

  /**
   * Send a request to the API
   * @param HttpRequestConfig request
   * @returns HttpClient<T>
   */
  request<T>(request: HttpRequestConfig): HttpClient<T> {
    const recast = new HttpClient<T>();
    // If a child class has disabled paytronix, don't add the keys
    if (this.service === "od") {
      request.url = `${request.url}${request.url?.includes("?") ? "&" : "?"}`;
      request.url = `${request.url}key=${config.PAYTRONIX_API_KEY}&provider_env=${config.PAYTRONIX_PROVIDER_ENV}`;
      if (this.auth_grant) {
        request.url = `${request.url}&access_token=${this.auth_grant}`;
      }
      if (this.guestIdentifier) {
        request.url = `${request.url}&${this.guestIdentifier.name}=${this.guestIdentifier.value}`;
      }
      if (this.needsMerchantId) {
        request.url = `${request.url}&merchant_id=${config.PAYTRONIX_MERCHANT_ID}&provider=px`;
      }
    }

    recast.config = request;
    if (this.service === "wp") {
      recast.config.baseURL = config.WP_URL_BASE;
    } else if (this.service === "ptx") {
      recast.config.baseURL = config.PAYTRONIX_API_URL;
    }
    if (this.perimeterXDebug) {
      if(!recast.config.headers) recast.config.headers = headers;
      recast.config.headers["x-px-block"] = "1";
      recast.config.headers["User-Agent"] = "PhantomJS/1.0";
    }

    recast.fetch = async () => {
      try {
        let response: HttpResponse<T> = await this.getInstance().request<T>(
          request
        );
        // response = phpToUndefined(response);
        // response = phpToDate(response);

        // if (request.validator) {
        //   try {
        //     request.validator.parse(response.data);
        //   } catch (error) {
        //     return Promise.reject(error);
        //   }
        // }
        return response.data;
      } catch (error) {
        throw error;
      }
    };

    return recast;
  }

  /**
   * Set the bearer token for authentication
   * @param token string
   */
  // public setBearer(token: string | null): void {
  //   if (token) {
  //     HttpClient._instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  //   } else {
  //     this.removeBearer();
  //   }
  // }

  /**
   * Remove the bearer token for authentication
   * @param token string
   */
  // public removeBearer(): void {
  //   delete HttpClient._instance.defaults.headers.common.Authorization;
  // }

  /**
   * Accessor for the static instance
   */
  public getInstance() {
    return HttpClient._instance;
  }
}
