import { useRestaurantContext } from "contexts/restaurant-context";
import { TRestaurantType } from "types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MenuHeader = () => {
  const { menu, restaurant, restaurantType } = useRestaurantContext();

  if (!menu || !restaurant || !restaurantType) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-9 col-xl-8">
            <div className="hero-content">
              {restaurantType === TRestaurantType.NouriaKitchen ? (
                <img
                  className="hero-logo"
                  src="/wp-content/themes/nouria/assets/images/content/nourias-kitchen-logo.png"
                  alt="The logo for Nouria's Kitchen"
                />
              ) : restaurantType === TRestaurantType.Amatos ? (
                <img
                  className="hero-logo"
                  src="/wp-content/themes/nouria/assets/images/content/amatos-logo.png"
                  alt="The logo for Amatos Cafe"
                />
              ) : restaurantType === TRestaurantType.KrispyKrunch ? (
                <img
                  className="hero-logo"
                  src="/wp-content/themes/nouria/assets/images/content/kk-logo.png"
                  alt="The logo for Krispy Krunch"
                />
              ) : restaurantType === TRestaurantType.NouriaCafe ? (
                <img
                  className="hero-logo"
                  src="/wp-content/themes/nouria/assets/images/content/cafe-logo.svg"
                  alt="The logo for Cafe Nouria"
                  style={{ maxHeight: "100px" }}
                />
              ) : (
                <img
                  className="hero-logo"
                  src="/wp-content/themes/nouria/assets/images/content/my-nouria-logo.png"
                  alt="The logo for Krispy Krunch"
                />
              )}

              <hr className="hero-divider" />
              <h1 className="hero-title font-decor">
                {restaurantType === TRestaurantType.NouriaKitchen ? (
                  <>Fresh. Delicious.</>
                ) : restaurantType === TRestaurantType.Amatos ? (
                  <>In 1902, Amato’s Real Italian Sandwich was born.</>
                ) : restaurantType === TRestaurantType.KrispyKrunch ? (
                  <>YOU EITHER KNOW HOW GOOD IT IS OR YOU HAVEN’T TRIED IT.</>
                ) : restaurantType === TRestaurantType.NouriaCafe ? (
                  <>Fresh Everyday</>
                ) : (
                  <></>
                )}
              </h1>
              <h6 className="hero-text">
                <strong>
                  {restaurantType === TRestaurantType.NouriaKitchen ? (
                    <></>
                  ) : restaurantType === TRestaurantType.KrispyKrunch ? (
                    <></>
                  ) : restaurantType === TRestaurantType.NouriaCafe ? (
                    <></>
                  ) : restaurantType === TRestaurantType.Amatos ? (
                    <></>
                  ) : (
                    <>
                      We take great pride in the quality and value of our Nouria
                      products, and we set our standards high to ensure we
                      deliver only the best for our guests. With amazing savings
                      everyday stop in and stock up!
                    </>
                  )}
                </strong>
              </h6>
            </div>
          </div>
        </div>
        <div className="hero-bg align-items-end">
          {restaurantType === TRestaurantType.NouriaKitchen ? (
            <img
              src="/wp-content/themes/nouria/assets/images/content/nourias-kitchen-item.png"
              alt="A delicious chicken sandwich from Nouria's Kitchen"
              style={{ maxWidth: "600px" }}
            />
          ) : restaurantType === TRestaurantType.Amatos ? (
            <img
              src="/wp-content/themes/nouria/assets/images/content/amatos-item.png"
              alt="A delicious pizza from Amatos"
            />
          ) : restaurantType === TRestaurantType.KrispyKrunch ? (
            <img
              src="/wp-content/themes/nouria/assets/images/content/kk-item.png"
              alt="A delicious chicken sandwich from Krispy Krunch"
            />
          ) : restaurantType === TRestaurantType.NouriaCafe ? (
            <img
              src="/wp-content/themes/nouria/assets/images/content/cafe-nouria-item.png"
              alt="A delicious breakfast sandwich and coffee from Cafe Nouria"
            />
          ) : (
            <img
              src="/wp-content/themes/nouria/assets/images/content/my-nouria-item.png"
              alt="Candy from My Nouria"
            />
          )}
        </div>
      </div>
    </>
  );
};

/**
 *
 */

export default MenuHeader;

const LoadingSkeleton: React.FC = () => {
  return (
    <SkeletonTheme baseColor="#e9e9e9" highlightColor="#e3e3e3">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-9 col-xl-8">
            <div className="hero-content">
              <Skeleton width={300} height={44} />
              <hr className="hero-divider" />
              <h1 className="hero-title">
                <Skeleton width={450} height={60} />
              </h1>
              <h6 className="hero-text">
                <Skeleton height={12} count={2} />
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-bg"></div>
    </SkeletonTheme>
  );
};
