import * as Sentry from "@sentry/browser";
import { getUserProfile } from "api/endpoints";
import axios, { AxiosError } from "axios";
import _ from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useAppContext } from "./app-context";
import * as braze from "@braze/web-sdk";

interface IAuthContext {
  errors: Record<string, [error: string, blocking: boolean][]>;
  checkForPaytronixError: (response: any, error_key: string) => boolean;
  registerError: (
    error: string | string[],
    key?: string,
    blocking?: boolean
  ) => void;
  hasErrors: (key?: string) => boolean;
  hasBlockingErrors: (key?: string) => boolean;
  getErrors: (key?: string) => string[];
  clearErrors: (key?: string) => void;
  getProfile: (
    username?: string,
    cardNumber?: string,
    access_token?: string
  ) => Promise<boolean>;
  handleApiError: (
    error:
      | AxiosError<{
          error: {
            code: number;
            display_message: string | string[];
            messages: string | string[];
          };
        }>
      | string[]
      | string,
    key?: string
  ) => void;
  fieldErrors: { [key: string]: string };
  registerFieldError: (error: string, key: string) => void;
  profileLoading: boolean;
  setProfileLoading: (loading: boolean) => void;
  redirect: string | null;
  setRedirect: (redirect: string | null) => void;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IAuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<IAuthContextProviderProps> = ({
  children,
}) => {
  const [errors, setErrors] = useState<
    Record<string, [error: string, blocking: boolean][]>
  >({});
  const [redirect, setRedirect] = useState<string | null>(null); // Redirect to a specific page after login
  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({}); // Field errors are errors that are tied to a specific field
  const appContext = useAppContext();
  /**
   * These error handling methods are copied from the restuarant context.
   * TODO: Ask Domagoj if we can consolidate the error handling at the app level so that we don't have to duplicate this code.
   */

  /**
   * Register a Field Error
   */
  const registerFieldError = useCallback(
    (error: string, key: string) => {
      setFieldErrors((e) => {
        const updatedErrors = { ...e };
        updatedErrors[key] = error;
        return updatedErrors;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  /**
   * Register an error
   */
  const registerError = useCallback(
    (error: string | string[], key: string = "", blocking: boolean = false) => {
      setErrors((e) => {
        const updatedErrors = { ...e };
        updatedErrors[key] ??= [] as [string, boolean][];
        if (_.isArray(error)) {
          error.forEach((message) =>
            updatedErrors[key].push([message, blocking])
          );
        } else {
          updatedErrors[key].push([error, blocking]);
        }
        return updatedErrors;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  /**
   * Check if there are errors
   */
  const hasErrors = useCallback(
    (key?: string) => {
      return key === undefined
        ? !_.isEmpty(errors)
        : errors[key] && errors[key].length > 0;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );

  /**
   * Check if there are blocking errors
   */
  const hasBlockingErrors = useCallback(
    (key?: string) => {
      return key === undefined
        ? Object.values(errors).flatMap((e) =>
            Array.from(e).filter((t) => t[1] === true)
          ).length > 0
        : (Array.from(errors[key]).filter((t) => t[1] === true) ?? []).length >
            0;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors]
  );

  /**
   * Get the errors we currently have by key
   */
  const getErrors = useCallback(
    (key?: string) => {
      return key === undefined
        ? Object.values(errors).flatMap((e) => e.map((t) => t[0]))
        : errors[key]?.map((t) => t[0]) ?? [];
    },
    [errors]
  );

  /**
   * Clear the errors by key
   */
  const clearErrors = useCallback((key?: string) => {
    setErrors((e) => {
      return key === undefined ? {} : _.omit({ ...e }, [key]);
    });
    setFieldErrors({});
  }, []);

  /**
   * Handle an API error
   */
  const handleApiError = useCallback(
    (
      error:
        | AxiosError<{
            error: {
              code: number;
              display_message: string | string[];
              messages: string | string[];
            };
          }>
        | string[]
        | string,
      key?: string
    ) => {
      Sentry.captureException(error);
      clearErrors(key);
      if(axios.isAxiosError(error) && (error.code === "ERR_NETWORK" || error.code === 'ERR_INTERNET_DISCONNECTED')){
        appContext.setConnectionError(true);
        return;
      }
      registerError(
        _.isString(error) || _.isArray(error)
          ? error
          : error.response?.data?.error?.display_message ?? "",
        key
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, registerError]
  );

  const checkForPaytronixError = (response: any, error_key: string) => {
    // Check for all
    if (
      !response ||
      response.error !== undefined ||
      response.errorMessage !== undefined ||
      response.result === "uniquenessConflict"
    ) {
      // Is there an error message
      if (response.errorMessage)
        handleApiError(response.errorMessage, error_key);
      // Is there an error
      if (response.error) handleApiError(response.error, error_key);
      // THere's a uniqueness conflict
      if (response.result === "uniquenessConflict") {
        if (response.conflictingFields.indexOf("email") > -1) {
          registerFieldError("This email is already in use", "email");
        }
      }
      // Is there a validation error
      if (response.errorsByField) {
        Object.keys(response.errorsByField).forEach((field) => {
          if (response.errorsByField[field].length > 0) {
            response.errorsByField[field].forEach((error: any) => {
              if (error.text) registerFieldError(error.text, field);
            });
          }
        });
      }
      return false;
    }
    return true;
  };

  /**
   * Get the user's profile from paytronix
   */
  const getProfile = useCallback(
    async (username?: string, cardNumber?: string, access_token?: string) => {
      try {
        clearErrors("actions.auth.refresh");
        if (!username) username = appContext.username;
        if (!cardNumber) cardNumber = appContext.cardNumber;
        if (!access_token) access_token = appContext.accessToken;

        if (!username || !cardNumber) {
          handleApiError(
            "Username and Cardname must be set",
            "actions.auth.get_profile"
          );
          return false;
        }
        let authorizationToken;
        const grant = await appContext.attemptGrant(username, access_token);
        if (!grant || !grant.authorizationGrant || grant.error !== undefined) {
          handleApiError(
            "Could not get grant token",
            "actions.auth.get_profile"
          );
          return false;
        }
        authorizationToken = grant.authorizationGrant;
        const profile = await getUserProfile(
          cardNumber,
          authorizationToken
        ).fetch();

        if (!profile || profile.error !== undefined) {
          handleApiError(profile.error, "actions.auth.get_profile");
          return false;
        }
        // Persist profile in cache
        appContext.cacheProfile(profile);
        if (profile.id) {
          braze.changeUser(profile.id);
        }
        // Set the profile loading
        setProfileLoading(false);
        return true;
      } catch (error: any) {
        appContext.setNetworkError(error.message, error);
        if (error.response?.data?.error) {
          handleApiError(
            error.response?.data?.error,
            "actions.auth.get_profile"
          );
        }
        return false;
      }
    },
    [appContext, clearErrors, handleApiError]
  );

  return (
    <AuthContext.Provider
      value={{
        checkForPaytronixError,
        hasErrors,
        hasBlockingErrors,
        getErrors,
        clearErrors,
        errors,
        registerError,
        fieldErrors,
        handleApiError,
        registerFieldError,
        getProfile,
        profileLoading,
        setProfileLoading,
        redirect,
        setRedirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be used within a AuthContextProvider."
    );
  }
  return context;
};
