import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getNext30DaysOptions } from "utils/options";
import { formatPhoneNumber } from "utils/string";
import * as api from "../api/endpoints";
import { TOrderType, TOrderingFor } from "../types";
import { useAppContext } from "./app-context";
import { useRestaurantContext } from "./restaurant-context";
import {
  gaItemEvent,
  gaPurchaseEvent,
  itemsArray,
  itemsTotal,
} from "integrations/ga4";
import {
  BrazeEvent,
  brazeEvent,
  purchaseEvent,
  userModelFromOrder,
} from "integrations/braze";

export type TCheckoutStep = number;

export interface ICheckoutData {
  paymentType: "cc" | "pip" | string | null;
  orderType: TOrderType;
  orderingFor: TOrderingFor;
  orderDate?: string;
  eta?: string;
  primaryAddress?: string;
  secondaryAddress?: string;
  zip?: string;
  fullName?: string;
  phone?: string;
  email?: string;
  tip?: number;
  tipSelection?: string;
  fields?: { [formName: string]: string };
  specialInstructions?: string;
  billingAddress?: string;
  billingZip?: string;
  billingCity?: string;
  billingState?: string;
  billingCountry?: string;
}

interface IStepMetadata {
  type: "PaymentInformation";
}

export interface IPaymentInformationMetadata extends IStepMetadata {
  token: string | null;
  personalInfo: {
    fullName: string;
    phone: string;
    email: string;
  };
  billingInfo?: {
    address: string;
    zip: string;
    city: string;
    state: string;
    country: string;
  };
  mobilePayment?: "apple" | "google";
  type: "PaymentInformation";
}

interface ICheckoutContext {
  data?: ICheckoutData;
  updateData: (value: Partial<ICheckoutData>) => void;
  acceptsCashAndCredit?: boolean;
  canTip: boolean;
  maxTip: number;
  applyTip: (value: number, isCustomValue?: boolean) => void;
  recalculateTip: () => void;
  removeTip: () => void;
  checkoutStep: TCheckoutStep;
  checkoutContinue: (metadata?: IStepMetadata) => Promise<string | boolean>;
  checkoutBack: (delta?: number) => void;
  isDirty?: boolean;
  isValid?: boolean;
  updating: boolean;
  setUpdating: Dispatch<SetStateAction<boolean>>;
  totalAmount: number;
  validateStep?: () => boolean;
}

export const CheckoutContext = createContext<ICheckoutContext | undefined>(
  undefined
);

interface ICheckoutContextProviderProps {
  children: React.ReactNode;
}

export const CheckoutContextProvider: React.FC<
  ICheckoutContextProviderProps
> = ({ children }) => {
  const { profileName, getCachedProfile, authenticated } = useAppContext();
  const {
    restaurant,
    order,
    clearErrors,
    registerError,
    processOrder,
    handleApiError,
    gaResturantType,
  } = useRestaurantContext();
  const [errors, setErrors] = useState<string[]>([]);
  const getData = useCallback(() => {
    const data = localStorage.getItem("checkoutData-" + restaurant.id);
    return data ? JSON.parse(data) : undefined;
  }, [restaurant]);
  const [data, setData] = useState<ICheckoutData | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const [checkoutStep, setCheckoutStep] = useState<TCheckoutStep>(0);

  useEffect(() => {
    if (!restaurant) return;
    setData(getData());
  }, [restaurant, getData, setData]);

  const checkoutContinue = useCallback(
    async (metadata?: IStepMetadata) => {
      if (!order) {
        return false;
      }
      try {
        const items = itemsArray(restaurant, order, gaResturantType);
        let tax = order.tax ?? 0;
        tax = parseFloat(
          order.active_taxes
            .reduce((accumulator: number, tax: any) => {
              return tax.type === "tax"
                ? accumulator + parseFloat(tax.amount)
                : 0;
            }, tax)
            .toFixed(2)
        );
        switch (checkoutStep) {
          case 0:
            clearErrors("actions.checkout.validation");
            // Persist data in local storage
            localStorage.setItem(
              "checkoutData-" + restaurant.id,
              JSON.stringify(data)
            );
            const res = await api.validateOrder(order.id).fetch();

            if (!res.valid) {
              registerError(res.display_message, "actions.checkout.validation");
              return false;
            }
            gaItemEvent({
              event: "begin_checkout",
              menu_type: gaResturantType,
              currency: "USD",
              store_location: restaurant?.name ?? "",
              value: itemsTotal(items),
              // tax: tax,
              // shipping: order.delivery_fee,
              // tip: data && data.tip ? data.tip : undefined,
              items,
            });
            setCheckoutStep(1);
            return true;
          case 1:
            if (!data) {
              return false;
            }

            if (!metadata || metadata.type !== "PaymentInformation") {
              return false;
            }

            const { token, personalInfo, mobilePayment, billingInfo } =
              metadata as IPaymentInformationMetadata;

            let success;
            const coupon =
              order.coupons && order.coupons.length > 0 && order.coupons[0].code
                ? order.coupons[0].code
                : "";
            if (!mobilePayment) {
              success = await processOrder(
                token,
                personalInfo.fullName ?? "",
                personalInfo.phone ?? "",
                personalInfo.email ?? "",
                data.tip,
                data.fields,
                data.specialInstructions,
                billingInfo
              );
            } else {
              //todo handle mobile payment
              success = true;
            }

            console.log("GA Purchase Event", coupon, data, order);

            if (success) {
              gaPurchaseEvent({
                event: "purchase",
                menu_type: gaResturantType,
                currency: "USD",
                store_location: restaurant?.name ?? "",
                items,
                coupon,
                value: itemsTotal(items),
                tax: tax,
                shipping: order.delivery_fee,
                tip: data && data.tip ? data.tip : undefined,
                transaction_id: order.id,
              });

              if (order && order.items.length > 0 && restaurant) {
                order.items.forEach((item: any) => {
                  let metadata = {
                    product_id: item.category,
                    brand: restaurant.name,
                    item_desc: item.name,
                    is_promotion:
                      order.coupon && order.coupon.length > 0 ? "YES" : "NO",
                  };
                  console.log(
                    "Braze Purchase Event",
                    item.name,
                    item.total_price,
                    item.quantity,
                    metadata
                  );
                  purchaseEvent(
                    item.name,
                    item.total_price,
                    item.quantity,
                    metadata
                  );
                });
              }

              // Log the purchase event
              if (!authenticated) {
                if (order && order.items.length > 0 && restaurant) {
                  brazeEvent(
                    BrazeEvent.Online_Order_Anon_Checkout,
                    userModelFromOrder(order, personalInfo)
                  );
                }
              }

              window.localStorage.removeItem("checkoutData-" + restaurant.id);
            }
            return success;
          default:
            return false;
        }
      } catch (error: any) {
        handleApiError(error, "actions.checkout.validation");
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkoutStep,
      clearErrors,
      data,
      order,
      processOrder,
      registerError,
      restaurant,
      handleApiError,
      gaResturantType,
    ]
  );

  const checkoutBack = useCallback(
    (delta: number = 1) => {
      setCheckoutStep((value) => {
        switch (value) {
          case 0:
            return 0;
          default:
            return checkoutStep - delta;
        }
      });
    },
    [checkoutStep]
  );

  const updateData = useCallback(
    (value: Partial<ICheckoutData>) => {
      setData((currentData) => {
        if (!restaurant) {
          return;
        }

        localStorage.setItem(
          "checkoutData-" + restaurant.id,
          JSON.stringify({ ...(currentData ?? {}), ...value })
        );

        return currentData ? { ...currentData, ...value } : currentData;
      });
    },
    [restaurant]
  );

  const applyTip = useCallback(
    (value: number, isCustomValue: boolean = false) => {
      updateData(
        isCustomValue
          ? { tip: value, tipSelection: "custom" }
          : {
              tip: value ? _.round(order.subtotal * (value / 100), 1) : 0,
              tipSelection: String(value),
            }
      );
    },
    [order, updateData]
  );

  const recalculateTip = useCallback(
    () => {
      if (!data || !order) return;
      if (data.tipSelection !== "custom") {
        updateData({
          tip: data.tipSelection
            ? _.round(
                order.subtotal * (Number(data.tipSelection ?? 0) / 100),
                1
              )
            : 0,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order]
  );

  const removeTip = useCallback(() => {
    updateData({ tip: undefined, tipSelection: undefined });
  }, [updateData]);

  const canTip = useMemo((): boolean => {
    if (!order || !data?.paymentType) {
      return false;
    }

    const tipsBehaviour = order.checkout_behaviors?.tips;

    if (!tipsBehaviour) {
      return false;
    }

    return (
      tipsBehaviour.allowed &&
      (tipsBehaviour.allowed_payment_types ?? []).includes(data.paymentType)
    );
  }, [data, order]);

  const maxTip = useMemo(() => {
    if (!order || !data?.paymentType) {
      return 0;
    }

    const tipsBehaviour = order.checkout_behaviors?.tips;

    if (!tipsBehaviour) {
      return 0;
    }

    if (
      !tipsBehaviour.allowed ||
      !(tipsBehaviour.allowed_payment_types ?? []).includes(data.paymentType)
    ) {
      return 0;
    }

    return tipsBehaviour.max_value as number;
  }, [data, order]);

  const acceptsCashAndCredit = useMemo(() => {
    if (!order) {
      return;
    }

    return (
      (
        order.checkout_behaviors?.allowed_payment_types?.filter((pt: string) =>
          ["cc", "pip"].includes(pt)
        ) ?? []
      ).length === 2
    );
  }, [order]);

  const isDirty = useMemo(() => {
    if (!order || !data) {
      return false;
    }

    if (order.type !== data.orderType) {
      return true;
    }

    if (order.ordering_for !== data.orderingFor) {
      return true;
    }

    if (order.ordering_for === "later") {
      if (order.order_date !== data.orderDate || order.eta !== data.eta) {
        return true;
      }
    }

    if (order.type === "delivery") {
      if (order.address !== data.primaryAddress || order.zip !== data.zip) {
        return true;
      }
    }
  }, [data, order]);

  const isValid = useMemo(() => {
    let isValid = true;
    const collectErrors = [];
    if (data === undefined) {
      return false;
    }

    if (!data.orderingFor) {
      isValid = false;
      collectErrors.push("Please select an order time.");
    }
    if (order) {
      if (order.items.length === 0) {
        collectErrors.push("Please add items to your order.");
        isValid = false;
      }
      if (restaurant?.max_amount && order.subtotal > restaurant.max_amount) {
        isValid = false;
      }
    }

    if (data.orderingFor === "later") {
      if (!data.orderDate || !data.eta) {
        collectErrors.push("Please select a date and time for your order.");
        isValid = false;
      }
    }

    if (data.orderType === "delivery") {
      if (!data.primaryAddress || !data.zip) {
        collectErrors.push("Please enter your delivery address.");
        isValid = false;
      }
    }

    for (let field of restaurant?.fields ?? []) {
      // This field is appropriate for this order type
      if (field.order_type === "both" || field.order_type === data.orderType) {
        // Check to see if the field is required
        if (
          field.required !== undefined &&
          (!data.fields || !data.fields[field.form_name])
        ) {
          isValid = false;
          collectErrors.push(`${field.label} is required.`);
        }
        // Check to see if the field is too long
        if (
          field.character_limit &&
          data.fields &&
          data.fields[field.form_name] &&
          data.fields[field.form_name].length > field.character_limit
        ) {
          isValid = false;
          collectErrors.push(`${field.label} is too long.`);
        }
      }
    }

    if (checkoutStep === 1) {
      if (!data.fullName || !data.phone || !data.email) {
        isValid = false;
      }
    }
    if (!isValid) {
      setErrors(collectErrors);
    }
    return isValid;
  }, [checkoutStep, data, restaurant, order]);

  const totalAmount = useMemo(() => {
    return !order || !data
      ? 0
      : _.round(order.total_amount + (data?.tip ?? 0), 2);
  }, [data, order]);

  const validateStep = useCallback(() => {
    if (!isValid) {
      for (const error of errors) {
        registerError(error, "actions.checkout.validation");
      }
    }
    return isValid;
  }, [isValid, errors, registerError]);

  useEffect(() => {
    if (order === undefined || restaurant === undefined || data !== undefined)
      return;

    let orderingFor = order.ordering_for;
    let orderDate = order.order_date ?? "";

    if (!order.is_possible_submit) {
      // cannot order right now, set up for later
      orderingFor = "later";
      orderDate = getNext30DaysOptions(restaurant.tz)[0].value;
    }

    const checkoutBehaviors = order.checkout_behaviors;
    const allowedPaymentTypes = checkoutBehaviors?.allowed_payment_types ?? [];
    const paymentType = allowedPaymentTypes.includes("cc")
      ? "cc"
      : allowedPaymentTypes.includes("pip")
      ? "pip"
      : null;

    if (paymentType === null) {
      registerError(
        "No payment types available for this order.",
        "info.order.payment_type",
        true
      );
    }
    getCachedProfile().then((cachedProfile) => {
      const checkoutData: ICheckoutData = {
        paymentType: paymentType,
        orderType: order.type,
        orderingFor: orderingFor,
        orderDate: orderDate,
        eta: order.eta,
        primaryAddress: order.address ?? "",
        zip: order.zip ?? "",
        fullName: cachedProfile ? profileName(cachedProfile) : "",
        phone: cachedProfile
          ? formatPhoneNumber(cachedProfile?.phones[0]) ?? ""
          : "",
        email: cachedProfile ? cachedProfile.email ?? "" : "",
      };

      if (_.isArray(restaurant.fields)) {
        checkoutData.fields = {};
        (restaurant.fields as any[]).forEach((field: any) => {
          if (field.type === "select") {
            if(field.default_option) {
              checkoutData.fields![field.form_name] = field.default_option;
            } else if (field.options && field.options.length > 0) {
              checkoutData.fields![field.form_name] = field.options[0];
            }
          }
        });
      }

      setData(checkoutData);
    });
  }, [
    data,
    order,
    registerError,
    restaurant,
    updateData,
    getCachedProfile,
    profileName,
  ]);

  useEffect(() => {
    if (!order) return;
    recalculateTip();
  }, [recalculateTip, order]);

  useEffect(() => {
    if (canTip && order.checkout_behaviors?.tips?.default_percent) {
      applyTip(order.checkout_behaviors.tips.default_percent);
    } else {
      removeTip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canTip]);

  return (
    <CheckoutContext.Provider
      value={{
        data,
        updateData,
        acceptsCashAndCredit,
        canTip,
        maxTip,
        applyTip,
        recalculateTip,
        removeTip,
        checkoutStep,
        checkoutContinue,
        checkoutBack,
        isDirty,
        isValid,
        updating,
        setUpdating,
        totalAmount,
        validateStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error(
      "useCheckoutContext must be used within a CheckoutContextProvider."
    );
  }
  return context;
};
