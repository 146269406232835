export enum NouriaEnvironment {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}
const config: {
  ENVIRONMENT: NouriaEnvironment;
  DEBUG: boolean;
  PAYTRONIX_MERCHANT_ID: string;
  PAYTRONIX_API_KEY: string;
  PAYTRONIX_PROVIDER_ENV: string;
  PAYTRONIX_OD_API_URL: string;
  PAYTRONIX_API_URL: string;
  WP_URL_BASE: string;
  GOOGLE_MAPS_API_KEY: string;
  BRAZE_API_KEY: string;
  GOOGLE_PAY_MERCHANT_NAME: string;
  GOOGLE_PAY_MERCHANT_ID: string;
  GOOGLE_PAY_ENVIRONMENT: "TEST" | "PRODUCTION";
  SPREEDLY_GATEWAY_MERCHANT_ID: string;
  SENTRY_DSN: string;
} = {
  ENVIRONMENT:
    (process.env.NODE_ENV as NouriaEnvironment) ||
    NouriaEnvironment.DEVELOPMENT,
  DEBUG:
    process.env.REACT_APP_DEBUG === "true" ||
    (process.env.NODE_ENV as NouriaEnvironment) ===
      NouriaEnvironment.DEVELOPMENT,
  PAYTRONIX_MERCHANT_ID: "885",
  PAYTRONIX_API_KEY: process.env.REACT_APP_PAYTRONIX_API_KEY || "",
  PAYTRONIX_PROVIDER_ENV: process.env.REACT_APP_PAYTRONIX_PROVIDER_ENV || "train",
  PAYTRONIX_OD_API_URL: "https://oo-api.pxsweb.com",
  PAYTRONIX_API_URL: process.env.REACT_APP_PAYTRONIX_API_URL || "https://m885.api.pxslab.com/rest/24.2",
  WP_URL_BASE: process.env.REACT_APP_WP_URL_BASE || "https://www.nouria.test",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY || "",
  BRAZE_API_KEY:
    process.env.REACT_APP_BRAZE_API_KEY ||
    "",
  GOOGLE_PAY_MERCHANT_ID: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID || "",
  GOOGLE_PAY_ENVIRONMENT:
    process.env.REACT_APP_GOOGLE_PAY_ENVIRONMENT === "PRODUCTION"
      ? "PRODUCTION"
      : "TEST",
  GOOGLE_PAY_MERCHANT_NAME:
    process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME || "Nouria",
  SPREEDLY_GATEWAY_MERCHANT_ID:
    process.env.REACT_APP_SPREEDLY_GATEWAY_MERCHANT_ID || "",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || "",
};
export default config;
